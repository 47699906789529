import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  OverlayContainer,
  mergeProps,
  useOverlayPosition,
  useTooltip
} from "react-aria";
import { cn } from "../../utils/tailwind";
import { useTooltipContext } from "./TooltipContext";
const ContentInner = ({ children, className }) => {
  const { state, tooltipProps, triggerRef, placement } = useTooltipContext();
  const { tooltipProps: ariaTooltipProps } = useTooltip({}, state);
  const overlayRef = useRef(null);
  const {
    overlayProps,
    placement: resolvedPlacement,
    arrowProps
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    offset: 12,
    placement,
    isOpen: state.isOpen
  });
  if (!state.isOpen)
    return null;
  return /* @__PURE__ */ jsxs(
    "div",
    {
      ...mergeProps(overlayProps, tooltipProps, ariaTooltipProps),
      ref: overlayRef,
      className: cn(
        "relative z-popover max-w-xs rounded bg-[var(--s-tooltip-color-bg)] p-x2 text-xs text-[var(--s-tooltip-color-text)] shadow-diffuse",
        className
      ),
      children: [
        /* @__PURE__ */ jsx(
          "div",
          {
            ...arrowProps,
            style: {
              ...arrowProps.style,
              // Manually tweak the arrow position to ensure it centres correctly with rotation.
              left: `${Number(arrowProps.style?.left) - 4}px`
            },
            className: cn(
              "absolute size-2 bg-[var(--s-tooltip-color-bg)]",
              resolvedPlacement === "top" && "-bottom-x0_5 rotate-45",
              resolvedPlacement === "bottom" && "-top-x0_5 rotate-45",
              resolvedPlacement === "left" && "-right-x0_5 rotate-45",
              resolvedPlacement === "right" && "-left-x0_5 rotate-45",
              arrowProps.className
            )
          }
        ),
        children
      ]
    }
  );
};
const Content = ({ children, className }) => {
  return /* @__PURE__ */ jsx(OverlayContainer, { children: /* @__PURE__ */ jsx(ContentInner, { className, children }) });
};
export {
  Content
};

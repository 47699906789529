"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { Content } from "./components/Content";
import { Trigger } from "./components/Trigger";
const Tooltip = ({
  content,
  children,
  className,
  delay = 250,
  placement = "top"
}) => {
  return /* @__PURE__ */ jsxs(Trigger, { delay, placement, children: [
    children,
    /* @__PURE__ */ jsx(Content, { className, children: content })
  ] });
};
export {
  Tooltip
};

import { jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
const TooltipContext = createContext(null);
const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error(
      "useTooltipContext: Attempting to access undefined context, possibly outside of `TooltipProvider`"
    );
  }
  return context;
};
const TooltipProvider = ({
  children,
  ...value
}) => {
  return /* @__PURE__ */ jsx(TooltipContext.Provider, { value, children });
};
export {
  TooltipProvider,
  useTooltipContext
};
